<template>
   <v-container>
  <v-card elevation="0" class="pt-5">
    <div v-if="loadingStatus" class="table__spinnerWrapper">
      <app-spinner class="mt-5 mr-5"></app-spinner>
    </div>
    <div v-else>
      <v-container>
        <v-row>
          <template v-for="(info, key, index) of teacher">
            <v-col md="4" :key="index" v-if="info.value">
              <h3 class="ml-5">
                {{ $t(info.desc) }} :
                <span style="font-weight: 100">{{ info.value }}</span>
              </h3>
            </v-col>
          </template>
        </v-row>
      </v-container>

      <v-card elevation="0" class="mt-5">
        <v-toolbar>
          <template v-slot:extension>
            <v-tabs v-model="tab" align-with-title color="#757575">
              <v-tabs-slider></v-tabs-slider>
              <v-tab :disabled="disableTabs" :href="'#' + '1'">{{
                $t("Weekly table")
              }}</v-tab>
              <v-tab
                :disabled="disableTabs"
                :href="'#' + '2'"
                v-if="returnAbility('registration-record:data')"
              >
                {{ $t("responsibilties") }}
              </v-tab>
              <v-tab
                :disabled="disableTabs"
                :href="'#' + '3'"
                v-if="returnAbility('note:index')"
              >
                {{ $t("lessons") }}
              </v-tab>
              <v-tab :disabled="disableTabs" :href="'#' + '4'" v-if="returnAbility('account:statement')"
                @click="$router.push(`/accountStatement/${teacher.sub_account.account_id}/${teacher.sub_account.id}`);">
                  {{ $t("Account statement") }}
                </v-tab>
              <v-tab
                :disabled="disableTabs"
                :href="'#' + '5'"
                v-if="returnAbility('note:index')"
              >
                {{ $t("Digital Wallet") }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item value="1">
            <app-weekly-table v-if="tab == '1'"></app-weekly-table>
          </v-tab-item>
          <v-tab-item value="2">
            <app-responsibilities v-if="tab == '2'"></app-responsibilities>
          </v-tab-item>
          <v-tab-item value="3">
            <app-lessons v-if="tab == '3'"></app-lessons>
          </v-tab-item>
          <v-tab-item value="5">
            <app-buffet v-if="tab == '5' && returnAbility('digital_wallet:show')"></app-buffet>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </v-card>
</v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import weeklyTable from "./weeklyTable.vue";
import responsibilities from "./responsibilities.vue";
import lessons from "./lessons.vue";
import buffet from "./components/buffet/buffet.vue";


export default {
  components: {
    appWeeklyTable: weeklyTable,
    appResponsibilities: responsibilities,
    appLessons: lessons,
    appBuffet: buffet,
  },
  data() {
    return {
      tab: this.$store.state.tabTeachers,
      teacherId: this.$route.params.id,
    };
  },
  watch: {
    "tab": function () {
      this.$store.commit("SET_TABTEACHERS", this.tab);
    },
  },
  computed: {
    ...mapGetters({
      teacher: "teachers/getTeacherData",
      loadingStatus: "teachers/getLoadingStatus",
      getTableLoadingStatus: "teachers/getTableLoading",
    }),
    disableTabs() {
      if (this.getTableLoadingStatus) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchTeacher: "teachers/fetchTeacher",
    }),

    fetchData(page) {
      this.fetchTeacher(this.teacherId);
    },
  },
  created() {
    if (this.returnAbility("teacher:destroy")) {
      this.displayDeleteIcon = true;
    }

    this.fetchData(1);
  },
};
</script>
<style scoped>

</style>
