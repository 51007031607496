<template>
  <div v-if="loading" class="table__spinnerWrapper">
    <app-spinner class="mt-5 mr-5"></app-spinner>
  </div>
  <div v-else>
    <v-container>
      <v-row justify="center" align="center">
        <v-col md="12">
          <table class="table">
            <tbody>
              <th class="border"></th>
              <th
                scope="col"
                v-for="header in headers"
                :key="header"
                class="border"
              >
                {{ $t("order") }} {{ header }}
              </th>
              <tr v-for="(day, dayIndex) in days" :key="day">
                <th class="border">{{ $t(day) }}</th>

                <td
                  v-for="(header, headerIndex) in headers"
                  :key="header"
                  class="cellHover"
                >
                  <div v-for="item in arr" :key="item.id">
                    <div
                      v-if="
                        headerIndex == item.order - 1 &&
                        dayIndex == dayValues(item.day)
                      "
                      class="content"
                      @click="$router.push(`/view-responsibility/${item.id}`)"
                    >
                      {{ item.edu_class.name }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      arr: [],

      teacherId: this.$route.params.id,
      days: [
        "saturday",
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
      ],
      headers: [1, 2, 3, 4, 5, 6, 7],
    };
  },
  created: async function () {
    try {
      this.loading = true;
      const response = await axios
        .get(`/teacher/${this.teacherId}/subjects-table`)
        .then((res) => res.data.data);
    

      Object.values(response).forEach((val) => {
       val.map(item=>( this.arr.push(item)))
        
      });
  
    } catch (err) {
    } finally {
      this.loading = false;
    }
  },
  methods: {
    // print() {
    //   console.log("kkkkkkkkk");
    // },
    dayValues: function (day) {
      if (day == "saturday") return 0;
      else if (day == "sunday") return 1;
      else if (day == "monday") return 2;
      else if (day == "tuesday") return 3;
      else if (day == "wednesday") return 4;
      else if (day == "thursday") return 5;
    },
  },
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  margin-top: 1vw;
}
th {
  font-size: 1vw;
  color: rgb(51, 51, 51);
}
.border {
  border: 1px solid gray;
  background: rgb(248, 255, 238);
  background: linear-gradient(
    90deg,
    rgba(248, 255, 238, 1) 100%,
    rgba(0, 255, 0, 1) 100%,
    rgba(248, 255, 238, 1) 100%
  );
}
.cellHover {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.cellHover:hover {
  cursor: pointer;
  background-color: rgb(222, 247, 222);
}
.content {
  font-size: 1vw;
  font-weight: 500;
  color: rgb(2, 163, 2);
  letter-spacing: 1px;
  /* background-color: blue; */
  width: 100%;
  height: 100%;
}
td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  text-align: center;
}

tr:nth-child(even) {
  background-color: rgb(251, 251, 251);
}
</style>
