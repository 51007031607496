// eye
<template>
  <v-card elevation="0" class="pt-5">
    <div v-if="loadingStatus" class="table__spinnerWrapper">
      <app-spinner class="mt-5 mr-5"></app-spinner>
    </div>
    <div v-else>
      <v-container>
        <app-base-table
          :tableOptions="tableOptions"
          @re-fetch-data="fetchData"
          serverRoute="/responsibility"
          @re-fetch-paginated-data="fetchData($event)"
          :enableDelete="displayDeleteIcon"
          :flaggedItems="flaggedItemsData"
        >
          <template slot="editFeature" slot-scope="{ row }">
            
            <v-icon
              v-if="returnAbility('student:show')"
              color="green"
             @click="$router.push(`/view-responsibility/${row.id}`)"
              >mdi-eye</v-icon
            >
          </template>
        </app-base-table>
      </v-container>
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      flaggedItemsData: [
        "created_at",
        "email_verified_at",
        "image",
        "token",
        "type",
        "updated_at",
       
      ],
    };
  },
  computed: {
    ...mapGetters({
      tableOptions: "responsibilities/getTableOptions",
      loadingStatus: "responsibilities/getLoadingStatus",
    }),
  },

  methods: {
    ...mapActions({
      fetchResponsibilities: "responsibilities/fetchResponsibilities",
    }),

    fetchData(page) {
      this.fetchResponsibilities({
        params: {
          page,
          teacher_id: this.$route.params.id,
        },
      });
    },
  },
  created() {
    if (this.returnAbility("teacher:destroy")) {
      this.displayDeleteIcon = true;
    }

    this.fetchData(1);
  },
};
</script>
<style scoped>
.text {
  border: 1px solid rgb(182, 179, 179);
  width: 100%;
  padding: 0.5vw;
  border-radius: 0.3vw;
  color: gray;
}
</style>
